<template>
  <VContainer fluid>
    <DialogWrapper
      v-model="editDialog"
      persistent
      fullscreen
      no-click-animation
      @keydown.esc="handleClose"
    >
      <!-- v-if="editDialog" hack чтобы удалить компоненту,
       из-за этого страдает анимация открытия, подумать над решением,
        чтобы не удалять руками связь во всех компонентах-->
      <ControlPanelForm
        v-if="editDialog"
        :item="editItem"
        @close="handleClose"
        @save="handleSave"
      />
    </DialogWrapper>
    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          Компании
        </h1>
      </VCol>
    </VRow>
    <VRow align="end">
      <VCol>
        <TTTextField
          label="Поиск"
          large
          append-icon="fal fa-search"
          placeholder="Название компании"
          clearable
          hide-details
          @input="searchFunction"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol style="position:relative;">
        <TTDataTable
          :items="companies"
          :headers="headers"
          :loading="loading"
          :items-per-page="-1"
          hide-default-footer
          :search="search"
          data-test="table-control-panel"
        >
          <template #item.used_licenses_except_tests="props">
            <VRow
              justify="space-between"
              align="center"
            >
              <VCol>
                {{ props.item.used_licenses_except_tests }}
              </VCol>
              <VCol cols="auto">
                <TTBtn
                  text
                  @click="handleEditDialog(props.item)"
                >
                  <VIcon>
                    fal fa-pen
                  </VIcon>
                </TTBtn>
              </VCol>
            </VRow>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import ControlPanelForm from '@/components/forms/controlPanel/ControlPanelForm.vue';
import * as snamiApi from '@/services/backend/snami';
import { formatDate } from '@/utils';
import { debounce } from 'lodash';

export default {
  name: 'ControlPanelView',
  components: {
    ControlPanelForm,
    DialogWrapper,
  },
  data() {
    return {
      search: '',
      editDialog: false,
      editItem: null,
      customerUsers: [],
      customerUsersLoading: false,
      selectedCompany: 1,
      companies: [],
      headers: [
        {
          text: 'Компания',
          value: 'name',
          sortable: false,
          width: '327px',
        },
        {
          text: 'Выдано лицензий',
          value: 'total_license_count',
        },
        {
          text: 'Осталось лицензий',
          value: 'licenses_left',
        },
        {
          text: 'Количество тестовых пользователей',
          value: 'test_license_count',
        },
        {
          text: 'Использовано лицензий',
          value: 'used_license',
        },
        {
          text: 'Использовано лицензий без учета тестовых пользователей',
          value: 'used_licenses_except_tests',
        },
      ],
      customerHeaders: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Сотрудник',
          value: 'name',
        },
        {
          text: 'Дата начала',
          value: 'started',
        },
        {
          text: 'Дата создания',
          value: 'created',
        },
        {
          text: 'Дата окончания',
          value: 'ended',
        },
      ],
      loading: false,
      searchFunction: null,
    };
  },
  created() {
    this.getLicencesData();
    this.searchFunction = debounce(this.handleTextSearch, 300);
  },
  methods: {
    formatDate,
    async getLicencesData() {
      try {
        this.loading = true;
        const { data } = await snamiApi.getCompanyLicences();
        this.companies = this.recalculateLicenceStatistic(data.data);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async updateCustomerLicences(item) {
      try {
        const formattedItem = {
          id: item.id,
          total_count: item.total_license_count,
          test_license_count: item.test_license_count,
        };
        await snamiApi.updateCustomerLicenses({
          id: item.id,
          data: formattedItem,
        });
      } catch (e) {
        const { message } = e.response.data.error;
        this.$root.$emit('showNotifier', {
          title: 'При сохранении произошла ошибка',
          msg: message,
          timeout: 6000,
          type: 'error',
        });
        console.warn(e);
        throw new Error(e);
      }
    },
    recalculateLicenceStatistic(data) {
      return data.map((item) => ({
        ...item,
        licenses_left: item.total_license_count - item.used_license + item.test_license_count,
        used_licenses_except_tests: item.used_license - item.test_license_count,
      }));
    },
    handleClose() {
      this.editDialog = false;
      this.editItem = {};
      this.customerUsers = [];
    },
    async handleSave(item) {
      try {
        this.loading = true;
        await this.updateCustomerLicences(item);
        await this.getLicencesData();
        this.handleClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    handleEditDialog(item) {
      this.editDialog = true;
      this.editItem = item;
    },
    handleTextSearch(value) {
      this.search = value;
    },
    showHeader(index) {
      if (!this.selectedCompany) return true;
      return index === 0;
    },
  },
};
</script>

<style scoped>

</style>
