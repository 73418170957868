<template>
  <VContainer
    v-if="item"
    class="pa-6"
  >
    <VRow>
      <VCol
        offset="1"
        cols="10"
      >
        <VRow>
          <VCol cols="auto">
            <VAvatar
              size="96"
              color="secondary"
              data-test="avatar"
            >
              <span class="tt-text-headline-2">{{ avatarText }}</span>
            </VAvatar>
          </VCol>
          <VCol align-self="center">
            <span
              class="tt-text-headline-2"
              data-test="text-name-customer"
            >
              {{ item.name }}
            </span>
          </VCol>
          <VCol
            cols="auto"
            class="mr-n6"
          >
            <TTBtn
              text
              data-test-label="button-close"
              @click="onClose"
            >
              <VIcon color="tt-light-mono-100">
                fal fa-times
              </VIcon>
            </TTBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="6">
            <ControlPanelCard
              key="1"
              :value.sync="editEntity.total_license_count"
              editable
              card-data-test-title="text-name-field-licenses-issued"
              card-data-test-input-text="number-licenses-issued"
              card-data-test-input-field="field-license-issued"
              title="Выдано лицензий"
              @save="onSave"
            />
          </VCol>
          <VCol cols="6">
            <ControlPanelCard
              key="2"
              title="Количество тестовых пользователей"
              :value.sync="editEntity.test_license_count"
              card-data-test-input-field="field-number-test-users"
              card-data-test-input-text="number-test-users"
              card-data-test-title="text-field-name-number-test-users"
              editable
              @save="onSave"
            />
          </VCol>
          <VCol cols="6">
            <ControlPanelCard
              key="3"
              title="Использовано лицензий"
              :value.sync="editEntity.used_license"
              card-data-test-title="text-name-field-licenses-used"
              card-data-test-input-text="number-licenses-used"
            />
          </VCol>
          <VCol cols="6">
            <ControlPanelCard
              key="4"
              card-data-test-title="text-name-field-used-licenses-excluding-test"
              card-data-test-input-text="number-licenses-used-excluding-test-licenses"
              title="Использовано лицензий без учёта тестовых"
              :value.sync="editEntity.used_licenses_except_tests"
            />
          </VCol>
          <VCol cols="12">
            <ControlPanelCard
              key="5"
              :value.sync="editEntity.licenses_left"
              color="tt-light-green pale"
              card-data-test-title="text-name-field-licenses-left"
              card-data-test-input-text="number-licenses-left"
              title="Осталось лицензий"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="auto">
            <TTBtn
              large
              color="tt-secondary"
              depressed
              data-test="control-panel-form-button-cancel"
              @click="onClose"
            >
              Отменить
            </TTBtn>
          </VCol>
          <VCol cols="auto">
            <TTBtn
              depressed
              large
              data-test="control-panel-form-button-save"
              @click="onSave"
            >
              Сохранить
            </TTBtn>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import ControlPanelCard from '@/components/forms/controlPanel/ControlPanelCard.vue';
import { generateAvatarText } from '@/utils/stringHelpers';

export default {
  name: 'ControlPanelForm',
  components: { ControlPanelCard },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // Копируем входной объект, чтобы в случае отмены заново скопировать исходный из props.item
      editEntity: {},
    };
  },
  computed: {
    avatarText() {
      return generateAvatarText(this.item?.name);
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.editEntity = { ...this.item };
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      this.$emit('save', this.editEntity);
    },
  },
};
</script>
