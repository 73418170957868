<template>
  <VCard
    :color="color"
    flat
    class="rounded-lg"
    min-height="122"
  >
    <VCardText>
      <VRow
        dense
        justify="space-between"
        align="center"
      >
        <VCol>
          <div
            class="tt-text-body-1"
            :data-test="cardDataTestTitle"
          >
            {{ title }}
          </div>
        </VCol>
        <!--        TODO  раскомментить в случае, если вернемся к логике с двумя состояними-->
        <!--        <VCol-->
        <!--          v-if="editable"-->
        <!--          cols="auto"-->
        <!--        >-->
        <!--          <TTBtn-->
        <!--            text-->
        <!--            :data-test-label="cardDataTestLabelToggleButton"-->
        <!--            @click="handleEditToggle"-->
        <!--          >-->
        <!--            <VScrollXReverseTransition mode="out-in">-->
        <!--              <VIcon-->
        <!--                v-if="!edit"-->
        <!--                key="not-edit"-->
        <!--                data-test="icon-edit"-->
        <!--              >-->
        <!--                fal fa-pen-->
        <!--              </VIcon>-->
        <!--              <VIcon-->
        <!--                v-else-->
        <!--                key="edit"-->
        <!--                data-test="icon-accept"-->
        <!--                color="success"-->
        <!--              >-->
        <!--                fal fa-check-->
        <!--              </VIcon>-->
        <!--            </VScrollXReverseTransition>-->
        <!--          </TTBtn>-->
        <!--        </VCol>-->
      </VRow>
      <VRow dense>
        <VCol>
          <div
            v-if="!editable"
            class="tt-text-headline-2"
            :data-test="cardDataTestInputText"
          >
            {{ value }}
          </div>
          <TTTextField
            v-else
            v-model="valueData"
            large
            type="number"
            :data-test-label="cardDataTestInputField"
            placeholder="0"
            @input="handleInput"
          />
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
<script>
export default {
  name: 'ControlPanelCard',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: 'tt-light-mono-4',
    },
    title: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    cardDataTestTitle: {
      type: String,
      default: 'control-panel-card-title',
    },
    cardDataTestLabelToggleButton: {
      type: String,
      default: 'control-panel-card-button-toggle',
    },
    cardDataTestInputField: {
      type: String,
      default: 'control-panel-card-input',
    },
    cardDataTestInputText: {
      type: String,
      default: 'control-panel-card-input',
    },
  },
  data() {
    return {
      valueData: this.value,
      edit: false,
    };
  },
  methods: {
    handleInput() {
      this.$emit('update:value', this.valueData ? this.valueData : 0);
    },
    handleEditToggle() {
      this.edit = !this.edit;
    },
  },
};
</script>
